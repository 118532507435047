import { BuildingState } from './-types'
import { CurrentView, FloorViewMode } from './-constants'

export default {
  clearSpaceData(state: BuildingState) {
    state.space = {}
  },
  setInitialState(state: BuildingState, payload: BuildingState) {
    state.currentView = payload.currentView
    state.canvasElement = payload.canvasElement
    state.cdnBase = payload.cdnBase
    state.buildingCode = payload.buildingCode?.toLowerCase()
    state.project = payload.project
    state.activeProject = payload.activeProject
    state.client = payload.client
    state.space = payload.space
    state.isInitialized = true
    state.settings = payload.settings
    state.floorViewMode = FloorViewMode.DEFAULT
  },

  clearState(state: BuildingState) {
    state.currentView = CurrentView.BUILDING
    state.canvasElement = null
    state.cdnBase = ''
    state.buildingCode = ''
    state.project = {}
    state.activeProject = ''
    state.client = ''
    state.space = {}
    state.isInitialized = false
    state.fitoutData = {
      initialized: false,
      data: []
    }
    state.loadingScreenActive = true
    state.finishedLoadingAssets = false
    state.settings = {}
    state.floorViewMode = FloorViewMode.DEFAULT
  },
  setSpace(state: BuildingState, payload: BuildingState) {
    state.space = payload
  },
  setCurrentView(state: BuildingState, payload: CurrentView) {
    state.currentView = payload
  },
  setFitoutData(state: BuildingState, payload: any) {
    state.fitoutData = payload
  },
  setLoadingScreenStatus(state: BuildingState, payload: boolean) {
    state.loadingScreenActive = payload
  },
  setLoadingAssetsStatus(state: BuildingState, payload: boolean) {
    state.finishedLoadingAssets = payload
  },
  setFloorViewMode(state: BuildingState, payload: FloorViewMode) {
    state.floorViewMode = payload
  },
  setAssetLoadingError(state: BuildingState, payload: string) {
    state.assetLoadingError = payload
  }
}
