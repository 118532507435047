import { ProjectState } from './-types'

export default (): ProjectState => ({
  isLoading: true,
  activeProject: '',
  projects: [],
  buildings: [],
  project: {
    isLoading: true,
    buildings: [],
    floors: [],
    spaces: [],
    currentSpaceId: ''
  },
  filters: {
    building: "",
    floor: "",
    surface: "",
  },
  availabilityResults: {
    availableSpaces: 0
  }
})
