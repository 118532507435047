import { BaseState } from './-types'

export default (): BaseState => ({
  language: 'en',
  sidebarOpen: false,
  infoOpen: false,
  filterOpen: true,
  mobileAvailabilityMenuOpen: false,
  meta: {},
  pageLoading: true,
  documentIsReady: false,
  landlordDetails: {},
  engine3dPluginLoading: false,
})
