// Actions
const CHANGE_LANGUAGE = 'changeLanguage'
const LOAD_METADATA = 'loadMetaData'
const CHANGE_SIDEBAR_STATE = 'changeSidebarState'
const CLOSE_SIDEBAR_STATE = 'closeSidebarState'
const CHANGE_FILTER_STATE = 'changeFilterState'
const CHANGE_PAGE_LOADING_STATE = 'changePageLoadingState'
const TOGGLE_SIDEBAR = 'toggleSidebar'
const TOGGLE_FILTER = 'toggleFilter'
const SHOW_FILTER = 'showFilter'
const TOGGLE_INFO = 'toggleInfo'
const CLOSE_FILTER = 'closeFilter'
const TOGGLE_MOBILE_AVAILABILITY_MENU = 'toggleMobileAvailabilityMenu'
const CHANGE_MOBILE_AVAILABILITY_MENU = 'changeMobileAvailabilityMenu'
const CHANGE_DOCUMENT_READY_STATE = 'changeDocumentReadyState'

// Mutations
const SET_LANGUAGE = 'setLanguage'
const SET_METADATA = 'setMetaData'
const SET_SIDEBAR_STATE = 'setSidebarState'
const SET_FILTER_STATE = 'setFilterState'
const SET_INFO_STATE = 'setInfoState'
const SET_PAGE_LOADING_STATE = 'setPageLoadingState'
const SET_MOBILE_AVAILABILITY_MENU = 'setMobileAvailabilityMenu'
const SET_DOCUMENT_READY_STATE = 'setDocumentReadyState'
const SET_ENGINE_3D_PLUGIN_LOADING = 'setEngine3DPluginLoading'

// Namespace
const NAMESPACE = 'base'

const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`
}

export default {
  action: {
    CHANGE_LANGUAGE,
    TOGGLE_SIDEBAR,
    TOGGLE_FILTER,
    SHOW_FILTER,
    TOGGLE_INFO,
    CLOSE_FILTER,
    CHANGE_SIDEBAR_STATE,
    CLOSE_SIDEBAR_STATE,
    CHANGE_FILTER_STATE,
    LOAD_METADATA,
    CHANGE_PAGE_LOADING_STATE,
    TOGGLE_MOBILE_AVAILABILITY_MENU,
    CHANGE_MOBILE_AVAILABILITY_MENU,
    CHANGE_DOCUMENT_READY_STATE
  },
  mutation: {
    SET_LANGUAGE,
    SET_SIDEBAR_STATE,
    SET_FILTER_STATE,
    SET_INFO_STATE,
    SET_METADATA,
    SET_PAGE_LOADING_STATE,
    SET_MOBILE_AVAILABILITY_MENU,
    SET_DOCUMENT_READY_STATE,
    SET_ENGINE_3D_PLUGIN_LOADING
  },
  namespace: NAMESPACE,
  withNamespace
}
