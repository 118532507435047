import { render, staticRenderFns } from "./project.vue?vue&type=template&id=27255670&"
import script from "./project.vue?vue&type=script&lang=js&"
export * from "./project.vue?vue&type=script&lang=js&"
import style0 from "./project.vue?vue&type=style&index=0&id=27255670&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/ProjectTemplate.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Flayouts%2Fproject.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default,OrganismsOrganismSidebar: require('/app/components/organisms/OrganismSidebar.vue').default,OrganismsOrganismMenuBarProject: require('/app/components/organisms/OrganismMenuBarProject.vue').default})
